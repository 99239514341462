.orange{
    background-color: #FF6634;
}

/* .menu-item.menu-item-transparent.menu-item-active
{
    color: white !important
}

.menu-item.menu-item-transparent {
    color: white !important
} */

.input {
    --tw-border-opacity: 1 !important;
    appearance: none !important;
    border-color: rgb(209 213 219/var(--tw-border-opacity))!important;
    border-radius: 0.375rem !important;
    border-width: 1px !important;
    padding: 0.5rem 0.75rem !important;
    transition-duration: .15s !important;
    transition-property: color,background-color,border-color,text-decoration-color,fill,stroke,opacity,box-shadow,transform,filter,-webkit-backdrop-filter !important;
    transition-property: color,background-color,border-color,text-decoration-color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter !important;
    transition-property: color,background-color,border-color,text-decoration-color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter,-webkit-backdrop-filter !important;
    transition-timing-function: cubic-bezier(.4,0,.2,1) !important;
    width: 100%;
}
.input:focus{
    border:1px solid transparent !important;
}

.px-6 {
    padding-top: 0.5rem !important;
    padding-left: 0.5rem;
    padding-right: 1rem;
}

.table-default tbody>tr>td {
    padding: 0.4rem 1.5rem;
}
@media (min-width: 1280px) {
    .xl\:min-w-\[450px\] {
        min-width: 450px;
        font-size: 13px !important
    }
}
.custom-input {
  padding-left: 2.5rem; /* Adjust as necessary */
}

.menu-item.menu-item-transparent {
    --tw-text-opacity: 1;
    height: 30px !important;
    color: rgb(75 85 99 / var(--tw-text-opacity));
}

.side-nav-transparent {
    background-color: #121F34;
}
.menu-item.menu-item-transparent{
    color: rgb(149 145 145);
}


.menu-collapse-item.menu-collapse-item-transparent:hover{
    
    color: white;

}

.menu-collapse-item.menu-collapse-item-transparent{
    color: rgb(149 145 145);
}
/* .menu-collapse{
    
    color: white;
    
} */

.menu-item.menu-item-transparent.menu-item-active svg{
    color: #6366F1;
    }


.menu-item.menu-item-transparent.menu-item-hoverable:hover{
    background-color: #252E3E;
    color: white;

}
.menu-item.menu-item-transparent.menu-item-active {
background-color: #252E3E;
color: white;
}

*, ::backdrop, :after, :before {
    /* color: rgb(149 145 145); */
}
.h-16 {
    padding-left: unset;
    height: 4rem;
}
.px-4 {
    padding-left: 0.9rem;
    padding-right: 0.9rem;
    padding-bottom: 0.9rem;
    padding-top: 0.9rem;
}